import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

function Pill(props) {
  return (
    <span
      className="pill"
      style={{
        color: props.pillTextColor,
        backgroundColor: props.pillBgColor,
      }}
    >
      {props.pillText}
    </span>
  )
}

export default function(props) {
  return (
    <article
      className={`post-card ${props.count % 3 === 0 && `post-card-large`} ${
        props.postClass
      } ${props.node.frontmatter.thumbnail ? `with-image` : `no-image`}`}
      style={
        props.node.frontmatter.thumbnail && {
          backgroundImage: `url(${props.node.frontmatter.thumbnail.childImageSharp.fluid.src})`,
        }
      }
    >
      <Link to={props.node.fields.slug} className="post-card-link">
        <div className="post-card-content">
          <h2 className="post-card-title">
            {props.node.frontmatter.title || props.node.fields.slug}
          </h2>
          <p className="post-card-description">
            {props.node.frontmatter.description}
          </p>
        </div>
      </Link>
      <div className="pill-area">
        {props.node.frontmatter.pillText &&
          props.node.frontmatter.pillText
            .split(",")
            .map(data => (
              <Pill
                pillText={data}
                pillBgColor={props.node.frontmatter.pillBgColor}
                pillTextColor={props.node.frontmatter.pillTextColor}
              />
            ))}
      </div>
    </article>
  )
}
